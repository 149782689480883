<template>
  <HeroQuickLinksButton
    v-editable="blok"
    icon="drone"
    :label="blok.label"
    @click="onClick"
  />
</template>

<script setup lang="ts">
import type { BlockHeroQuickLinksMenuAerialGalleryStoryblok } from '@/types/storyblok'

defineProps<{ blok: BlockHeroQuickLinksMenuAerialGalleryStoryblok }>()

const { toggleAerialMap } = useUIStore()

function onClick(): void {
  toggleAerialMap(true)
}
</script>
